// Import dependencies
import React from "react";

// Import styles
import "./header-separator.css";
import {connect} from "react-redux";

/*
    Component that separates header and the remaining page
*/
class HeaderSeparator extends React.Component {


    render() {
        return this.props.isHeaderVisible ?
            <div className="hs__container" style={this.props.more ? {marginBottom: 20} : null}/> : null;
    }
}

function mapStateToProps(state) {
    return {isHeaderVisible: state.headerState.isHeaderVisible};
}

export default connect(mapStateToProps)(HeaderSeparator)
