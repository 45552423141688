

// Import dependencies
import React from "react";
import {navigate} from "gatsby";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import { PageContainer } from "../styled-components/UILibrary";
import StepsIndicator from "../components/checkout/stepsIndicator";
import TitleSection from "../components/sections/titleSection";
import SectionSeparator from "../components/separators/sectionSeparator";
import Step3Form from "../components/checkout/step3Form";
import HeaderSeparator from "../components/separators/headerSeparator";
import CheckoutCart from "../components/checkout/checkout_cart";
import StatisticsPopup from "../components/form/forms/statisticsPopup";
import Logo from "../assets/icons/logo_green_login.svg";
import { Link } from "gatsby"

// Import styles
import "./step.css";
import "./recycle.css";
import { Constants } from "../constants/Constants";
import { DEPLOY_TYPE, STRIPE_CONSTANT } from "../constants/stripe";

// Import redux
import { connect } from "react-redux";
import { pushOrderInformation } from "../redux/actions/checkoutAction";
import {getFirebase} from "../api/firebase";
import {getCartQuote} from "../util/cart";

/*
    Step 2 of the checkout process page
*/
class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.togglePopup = this.togglePopup.bind(this);
    this.updatePromoCode = this.updatePromoCode.bind(this);
    this.applyPromoCode = this.applyPromoCode.bind(this);
    this.isBrowser = this.isBrowser.bind(this);

    this.updateAdditionalManpower = this.updateAdditionalManpower.bind(this);
//    this.updateTools = this.updateTools.bind(this);
    this.updateWorksiteDetails = this.updateWorksiteDetails.bind(this);
    this.updateDataManagement = this.updateDataManagement.bind(this);

    const {
      location: { state },
    } = this.props;
    const {codePromoPrev, creditPreference} = state ? state : {};
    this.state = {
      mobileShow: false,
      popupOpen: false,
      popupContent: "",

      refealCodesArray: [],
      codePromoInput: "",
      codePromo: codePromoPrev ? codePromoPrev : {code: "", value: 0},
      creditPreference: creditPreference,
      additionalManpower: [
        {
          name: "Driver + 1 pax",
          price: 30,
          selected: false,
          priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].additionalManpower.driver1
        },
        {
          name: "Driver + 2 pax",
          price: 60,
          selected: false,
          priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].additionalManpower.driver2
        }
      ],
      tools: [
        {
          name: "Trolley required",
          price: 0,
          selected: false,
          priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].tools
        }
      ],
      worksiteDetails: [
        {
          name: "Walkup (with >10kg)",
          price: 15,
          selected: false,
          priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].worksiteDetails.walkup
        },
        {
          name: "Non-residential",
          price: 10,
          selected: false,
          priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].worksiteDetails.nonResidential
        }
      ],
      dataManagement: [
        {
          name: "For 1-5 items",
          price: 10,
          selected: false,
          priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].dataManagement.items1_5
        },
        {
          name: "For 6-20 items",
          price: 60,
          selected: false,
          priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].dataManagement.items6_20
        },
        {
          name: "For >20 items",
          price: 160,
          selected: false,
          priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].dataManagement.items20plus
        }
      ]
    };
  }

  isBrowser = () => typeof window !== "undefined";

  componentWillMount() {
    if (this.isBrowser() && !this.props.loggedIn) {
      navigate("/login");
    }
  }

  updatePromoCode = (e) => {
    this.setState({ codePromoInput: e.target.value });
  }

  applyPromoCode = () => {
    if (this.state.refealCodesArray.includes(this.state.codePromoInput)) {
      this.setState({ codePromo: {code: this.state.codePromoInput, value: 5}});
    } else {
      this.setState({ codePromo: {code: "", value: 0}});
    }
  }
  // Adds extra input fields depending on what is selected in
  // "How do you want to be credited?" field
  togglePopup = (content) => {
    this.setState({
      popupOpen: !this.state.popupOpen,
      popupContent: content
    });
  }
  componentDidMount() {
//    if (getCartQuote(this.props.cart, this.props.generalCart) < 15) {
    if (!(this.props.cart.length + this.props.generalCart.length)) {
      navigate("/");
    }
    const app = import("firebase/app");
    const firestore = import("firebase/firestore");
    Promise.all([app, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .firestore().collection("refeal_codes").get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.state.refealCodesArray.push(doc.data().code);
          });
      });
    });

  }

  updateAdditionalManpower = (i) => {
    let additionalManpower = [
      {
        name: "Driver + 1 pax",
        price: 30,
        selected: false,
        priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].additionalManpower.driver1
      },
      {
        name: "Driver + 2 pax",
        price: 60,
        selected: false,
        priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].additionalManpower.driver2
      }
    ];
    additionalManpower[i].selected = !this.state.additionalManpower[i].selected
    this.setState({ additionalManpower });
  }
  updateTools = (i) => {
    let tools = [
      {
        name: "Trolley required",
        price: 0,
        selected: false,
        priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].tools
      }
    ];
    tools[i].selected = !this.state.tools[i].selected
    this.setState({ tools });
  }
  updateWorksiteDetails = (i) => {
    
    let worksiteDetails = this.state.worksiteDetails;
    worksiteDetails[i].selected = !worksiteDetails[i].selected
    this.setState({ worksiteDetails });
  }
  updateDataManagement = (i) => {
    let dataManagement = [
      {
        name: "For 1-5 items",
        price: 10,
        selected: false,
        priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].dataManagement.items1_5
      },
      {
        name: "For 6-20 items",
        price: 60,
        selected: false,
        priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].dataManagement.items6_20
      },
      {
        name: "For >20 items",
        price: 160,
        selected: false,
        priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["ADDONS_PRICES"].dataManagement.items20plus
      }
    ];
    dataManagement[i].selected = !this.state.dataManagement[i].selected
    this.setState({ dataManagement });
  }
  
  render() {
    const chevronRight = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M2 1l1-1 4 4 1 1-1 1-4 4-1-1 4-4"></path></svg>;
    const { location: { state } } = this.props
    return (
      <Layout noFooter noHeader stepPage checkoutWhite noWhatsapp>
        <SEO title="Checkout" />
        <StatisticsPopup popupOpen={this.state.popupOpen} togglePopup={this.togglePopup} content={this.state.popupContent}/>
          <div className="checkout_page_container" style={this.state.mobileShow ? {height: "100%"} : {}}>
            <CheckoutCart
            codePromo={this.state.codePromo}
            updatePromoCode={this.updatePromoCode}
            applyPromoCode={this.applyPromoCode}
            togglePopup={this.togglePopup}
            mobileShow={this.state.mobileShow}
            onMobileTrigger={() => this.setState({mobileShow: !this.state.mobileShow})}
            collectionFee={state && state.collectionFee}
            />
            {/* Process indicator */}
            <div className={!this.state.mobileShow ? "checkout_page_forms" : "checkout_page_forms checkout_page_forms_hide"}>
              <Link to="/" className="checkout_logo" >
                <Logo />
              </Link>
              <span className="step_indicator">Contact{chevronRight}Schedule{chevronRight}<b>Add-ons</b>{chevronRight}Review</span>
              <div className="checkout_next_step">
                <div className="step_tittle">Contact</div>
                <span className="active" onClick={() => navigate("/step-1", { state: {codePromoPrev: this.state.codePromo} })}>Edit</span>
              </div>
              <div className="checkout_next_step">
                <div className="step_tittle">Schedule</div>
                <span className="active" onClick={() => navigate("/step-2", { state: {codePromoPrev: this.state.codePromo} })}>Edit</span>
              </div>
              <Step3Form
                mobileShow={this.state.mobileShow}
                onMobileTrigger={() => this.setState({mobileShow: !this.state.mobileShow})}

                creditPreference={this.state.creditPreference}

                collectionFee={state && state.collectionFee}

                codePromo={this.state.codePromo}

                additionalManpower={this.state.additionalManpower}
                tools={this.state.tools}
                worksiteDetails={this.state.worksiteDetails}
                dataManagement={this.state.dataManagement}

                updateAdditionalManpower={this.updateAdditionalManpower}
                updateTools={this.updateTools}
                updateWorksiteDetails={this.updateWorksiteDetails}
                updateDataManagement={this.updateDataManagement}
              />
            </div>
          </div>
      </Layout>
    );
  }
}

// Connect redux to component
const mapStateToProps = state => ({
  cart: state.cart,
  generalCart: state.generalCart,
  userToken: state.user.userToken,
  loggedIn: state.user.isLoggedIn
});

export default connect(mapStateToProps)(Step3);
