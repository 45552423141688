// Import dependencies
import React from "react";
import { Row, Col } from "antd";
import { navigate, Link } from "gatsby";
import { Form } from "antd";
import { Button } from 'antd';

// Import components
import Select from "../form/select/select";
import InputField from "../form/input/inputField";
import GeneralButton from "../form/buttons/GeneralButton";
import TitleSection from "../sections/titleSection"

// Import styles
import "../../pages/step.css";
import "../../pages/recycle.css";

// Import icons
import OpenWindow from "../../assets/icons/open-window.svg";

import {Separator} from "../separators/separator";

/*
    Form for the Step 2 of the checkout process page
*/
class Step2Form extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    let addonsArray = [
      ...this.props.additionalManpower
      .filter(item => item.selected),

      ...this.props.tools
      .filter(item => item.selected),

      ...this.props.worksiteDetails
      .filter(item => item.selected),

      ...this.props.dataManagement
      .filter(item => item.selected)
    ]
    navigate("/step-4",{
      state: {
        codePromoPrev: this.props.codePromo,
        creditPreference: this.props.creditPreference,
        addonsArray: addonsArray
      }
    });
  };

  render() {
    const { form } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
      >

        <div className="step_tittle">
          Add-ons
          <p>Add additional services to your booking</p>
        </div>
        <div className="step_tittle">
          Additional manpower
        </div>
        { this.props.additionalManpower.map( (item, i) => 
          <div
            className={ item.selected ? "select_option_button select_option_button_selected" : "select_option_button" }
            onClick={() => this.props.updateAdditionalManpower(i)}
          >
            <span>{item.name}</span>
            <span>{item.price}$</span>
          </div>
        )}
        <div className="step_tittle">
          Tools
        </div>
        { this.props.tools.map( (item, i) => 
          <div
            className={ item.selected ? "select_option_button select_option_button_selected" : "select_option_button" }
            onClick={() => this.props.updateTools(i)}
          >
            <span>{item.name}</span>
            <span>{item.price}$</span>
          </div>
        )}
        <div className="step_tittle">
          Worksite details
        </div>
        { this.props.worksiteDetails.map( (item, i) => 
          <div
            className={ item.selected ? "select_option_button select_option_button_selected" : "select_option_button" }
            onClick={() => this.props.updateWorksiteDetails(i)}
          >
            <span>{item.name}</span>
            <span>{item.price}$</span>
          </div>
        )}
        <div className="step_tittle">
          Data management
          <p>This add-on supersedes our <span onClick={() => navigate("https://help.reinin.sg/")}>data destruction guarantee</span>. Select this add-on only if you wish to receive an e-certificate for each item, confirming the data destruction for your audit purposes.</p>
        </div>
        { this.props.dataManagement.map( (item, i) => 
          <div
            className={ item.selected ? "select_option_button select_option_button_selected" : "select_option_button" }
            onClick={() => this.props.updateDataManagement(i)}
          >
            <span>{item.name}</span>
            <span>{item.price}$</span>
          </div>
        )}
        <div className="checkout_footer_mobile">
          <GeneralButton
              title="Next"
              type="primary"
              fit
              htmlType="submit"
              margin={{display: "block", marginLeft: "auto"}}
          />
          <span className="cart_checkout_header_switch" onClick={this.props.onMobileTrigger}>{!this.props.mobileShow ? "Show order summary" : "Return to checkout"}</span>          
        </div>

      </Form>
    );
  }
}

export default Form.create({ name: "checkout-2" })(Step2Form);
