export const DEPLOY_TYPE = "main"; //test

export const STRIPE_CONSTANT = {
    test: {
        STRIPE_KEY: "pk_test_WrWj85fxbkNWNFxUbFsKF5rb",
        BUSINESS_PLAN: "plan_GuMtHqw1jw6NUW",
        LIFESTYLE_PLAN: "plan_GuMqsP76zFheQ1",
        ADDONS_PRICES: {
            additionalManpower: {
                driver1: "price_1LXvyXFvtY0RW4Mh9rZNIiUr",
                driver2: "price_1LXvykFvtY0RW4MhghuVn7MG"
            },
            tools: "price_1LXvyvFvtY0RW4Mhz1sDOI8O",
            worksiteDetails: {
                walkup: "price_1LXvz9FvtY0RW4Mh7UbgOEe0",
                nonResidential: "price_1LXvzKFvtY0RW4MhiTTUP3n2"
            },
            dataManagement: {
                items1_5: "price_1LXvzUFvtY0RW4MhLVAHr9O1",
                items6_20: "price_1LXvzcFvtY0RW4Mhuaa2cdiz",
                items20plus:  "price_1LXvzmFvtY0RW4MhhautIV9a"
            }        
        },
        MEMBER_PLAN: {
            SME: {
                yearly: "price_1L2HSxFvtY0RW4MhFSb4kxa5",
                quarterly: "price_1L2HSxFvtY0RW4Mhh0aLOcET"
            },
            Regular: {
                yearly: "price_1L2HSAFvtY0RW4MhNCPQu45g",
                quarterly: "price_1L2HSAFvtY0RW4MhpIMP2ENr"
            }        
        },
        VEHICULE_PRICES: {
            Car: "price_1KtTjxFvtY0RW4MhDb4iyLSw",
            Lorry: "price_1KtTlAFvtY0RW4MhBxDXHbQG",
            Scooter: "price_1KtQrxFvtY0RW4MhhCL0Iv0Q",
            Van: "price_1KtTktFvtY0RW4MhOUR7pqmY"
        },
        URL: "https://quirky-poitras-f877b7.netlify.app/"
    },
    main: {
        STRIPE_KEY: "pk_live_GQuDO9Or74Irle2NJAAfrABJ",
        BUSINESS_PLAN: "plan_GwBbF7iWHvsLI0",
        LIFESTYLE_PLAN: "plan_GwBa3RU6G057Hz",
        ADDONS_PRICES: {
            additionalManpower: {
                driver1: "price_1Lhz8GFvtY0RW4MhCgCM0F3R",
                driver2: "price_1Lhz8EFvtY0RW4MhpyacPiri"
            },
            tools: "price_1Lhz8BFvtY0RW4MhT9KTNTA3",
            worksiteDetails: {
                walkup: "price_1Lhz88FvtY0RW4MhA8RSs4QZ",
                nonResidential: "price_1Lhz86FvtY0RW4MhhLtW0ZiV"
            },
            dataManagement: {
                items1_5: "price_1Lhz82FvtY0RW4MhocwIEqNo",
                items6_20: "price_1Lhz7zFvtY0RW4MhSVpJowWE",
                items20plus:  "price_1Lhz7wFvtY0RW4MhgXrbJ3Be"
            }
        },
        MEMBER_PLAN: {
            SME: {
                yearly: "price_1L8aRZFvtY0RW4MhWBIQCwIQ",
                quarterly: "price_1L8aRZFvtY0RW4MhSLRkO6iH"
            },
            Regular: {
                yearly: "price_1L8aRLFvtY0RW4Mhkmgs116Y",
                quarterly: "price_1L8aRLFvtY0RW4MhRUym1Zfl"
            }        
        },
        VEHICULE_PRICES: {
            Car: "price_1KtQdnFvtY0RW4MhDdX1LfHA",
            Lorry: "price_1KtQeHFvtY0RW4MhlKziaIUY",
            Scooter: "price_1KtQdXFvtY0RW4MhXOTsBrS0",
            Van: "price_1KtQe2FvtY0RW4Mh5D9boEn5"
        },
        URL: "https://reinin.sg/"
    }
}