// Import dependencies
import React from "react"

// Import components
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"

// Import styles
import "./title-section.css"

/*
    Component for section descriptions. Contains:
    1. Title
    2. Paragraph
*/
const TitleSection = ({
  title,
  paragraph,
  left,
  subHeader,
  subHeaderColour,
}) => (
  <div className={left ? "" : "title-section__container title-section__center"}>
    {subHeader ? (
      <ParagraphText margin="margin-bottom: 10px" colour={subHeaderColour}>
        {subHeader}
      </ParagraphText>
    ) : null}

    <TitleText>{title}</TitleText>
    {paragraph ? (
      <ParagraphText margin="margin-top: 20px">{paragraph}</ParagraphText>
    ) : null}
  </div>
)

export default TitleSection
